var addChildElement = require('./addChildElement'),
    createElement = require('./createElement'),
    eventHelper = require('./eventHelper'),
    bridgeBarStates = require('./bridgeBarStates'),
    popoverHandler,
    userDataIndex = {},
    analyticsEmitter;

module.exports = function(ph, ae) {
    var umWrapper = createElement('div', 'bridge-bar-user-menu');

    popoverHandler = ph;
    analyticsEmitter = ae;

    return {
        userMenuNode: function() {
            return umWrapper;
        },

        attach: function(bbEl) {
            bbEl.appendChild(umWrapper);
        },

        build: function(um) {
            if (window.document.querySelector('.bb-user-menu')) {
                umWrapper.innerHTML = '';
                userDataIndex = {};
            }

            var trigger = addChildElement(umWrapper, 'div', 'bb-user-menu'),
                popover = addChildElement(umWrapper, 'div', 'bb-popover');

            /* eslint-disable no-lonely-if */
            switch (um.bridgeBarState) {

                case bridgeBarStates.LOADING:
                    addChildElement(trigger, 'div', 'bb-user-loading', um.userLabelHtml);
                    break;
                case bridgeBarStates.ERROR:
                    addChildElement(trigger, 'div', 'bb-error');
                    break;
                case bridgeBarStates.RENDER:
                default:
                    var avatar = addChildElement(trigger, 'div', 'bb-user-avatar');

                    addChildElement(avatar, 'span', 'bb-initials', um.userLabelHtml);
            }

            addMessages(popover, um.messages);

            var body = addChildElement(popover, 'div', 'bb-popover__body');

            addUserData(body, um.userData);
            addUserLinks(body, um.userLinks);
            addLogOut(body, um.logOut);

            popoverHandler.attach('userMenu', 'user menu', trigger);
        },

        updateUserData: function(datum) {
            var item = userDataIndex[datum.keyHtml];

            if (item) {
                item.innerHTML = datum.valueHtml;
            } else {
                addUserDatum(umWrapper.querySelector('.bb-userdata'), datum);
            }
        }
    };
};

function addMessages(parent, messages) {
    if (!messages) {
        return;
    }

    var valid = messages.filter(function(msg) {
        return !!msg.messageHtml;
    });

    valid.forEach(function(msg) {
        var msgDiv = addChildElement(parent, 'div', 'bb-popover__message');

        if (msg.level) {
            msgDiv.classList.add(msg.level);
        }

        msgDiv.innerHTML = msg.messageHtml;
    });
}

function addUserLinks(parent, userLinks) {
    if (!userLinks) {
        return;
    }

    var valid = userLinks.filter(function(link) {
        return !!link.label;
    });

    if (valid.length) {
        var links = addChildElement(parent, 'section', 'bb-userlinks');

        valid.forEach(function(link) {
            addUserLink(links, link);
        });
    }
}

function addUserLink(parent, link) {
    addLink(parent, link, 'bb-userlink');
}

function addUserData(parent, userData) {
    if (!userData) {
        return;
    }

    var valid = userData.filter(function(datum) {
        return datum.keyHtml && datum.valueHtml;
    });

    if (valid.length) {
        var data = addChildElement(parent, 'section', 'bb-userdata');

        valid.forEach(function(datum) {
            addUserDatum(data, datum);
        });
    }
}

function addUserDatum(parent, datum) {
    var dl = addChildElement(parent, 'dl', 'bb-userdatum');

    addChildElement(dl, 'dt', 'bb-userdatum__key', datum.keyHtml);
    userDataIndex[datum.keyHtml] = addChildElement(dl, 'dt', 'bb-userdatum__value', datum.valueHtml);
}

function addLogOut(parent, logOut) {
    if (logOut) {
        addLink(parent, logOut, 'bb-logout');
    }
}

function addLink(parent, link, className) {
    var l = addChildElement(parent, 'a', className, link.label);

    l.target = link.target || '_top';

    if (link.className) {
        l.classList.add(link.className);
    }

    if (link.callback) {
        l.href = '#';
        l.addEventListener('click', function(e) {
            emitEvent(link.label, link.callback, 'click', e);
            link.callback();
            e.preventDefault();
        });
        l.addEventListener('contextmenu', function() {
            emitEvent(link.label, link.callback, 'contextmenu');
        });
    } else {
        l.addEventListener('click', function(e) {
            emitEvent(link.label, link.callback, 'click', e);
        });
        l.addEventListener('contextmenu', function() {
            emitEvent(link.label, link.callback, 'contextmenu');
        });
        l.href = link.url || '#';
    }
}

function emitEvent(label, callback, eventName, eventData) {
    label = (label || '').toLowerCase();
    analyticsEmitter.emit({
        event: 'userEvent',
        eventFeature: 'bridge bar',
        eventFeatures: 'bridge bar > user menu > popover',
        eventElement: label + ' link',
        eventAction: eventHelper.determineAction(eventName, eventData),
        eventResult: (callback ? 'invoked callback for ' : 'navigated to ') + label
    });
}

