var applyDefaults = require('./applyDefaults'),
    bridgeApiUrlBuilder = require('./bridgeApiUrlBuilder'),
    language = require('./languages'),
    bridgeBarStates = require('./component/bridgeBarStates');

module.exports = {
    getLoadingState: getLoadingState,
    convertFromApiPayload: convertFromApiPayload,
    applyOptions: applyOptions
};

function getLoadingState(config) {
    var languageFile = language(config.language);

    return {
        userMenuObject: {
            bridgeBarState: bridgeBarStates.LOADING,
            userLabelHtml:   languageFile['loading-state'].loading + '<i>.</i><i>.</i><i>.</i>&nbsp;&#x2304;',
            messages: [
                {
                    messageHtml:
                        languageFile['loading-state']['attempting-to-load-user-information']
                }
            ],
            logOut: buildLogOut(config)
        }
    };
}

/* eslint-disable camelcase, max-statements */
function convertFromApiPayload(data, config, loggedError) {
    /*
    Example Payload:
    {
      "id": "string",
      "username": "string",
      "given_name": "string",
      "family_name": "string",
      "solution_links": [
        {
          "label": "string",
          "uri": "string"
        }
      ],
      "display_info": [
        {
          "header": "string",
          "body": "string"
        }
      ],
      "user_links": [
        {
          "label": "string",
          "uri": "string",
          "icon": "string",
          "menu": "string",
          "mode: "string"
        }
      ],
      "feature_flags": {
        'pdt.example-FF': {
                value: true
            }
      },
       components: [
            {
                noModuleSrc: 'http://localhost:3333/build/test-tag.js',
                moduleSrc: 'http://localhost:3333/build/test-tag.esm.js',
                tag:'test-tag',
                attributes:  {
                    border: 'green',
                    clientid: '0oafxwea4r7Isz4H6357',
                    scopes: 'openid bridge.signin offline_access profile'
                }
            }
        ]
    }
    */

    // Base Prototype for the User Menu
    var languageFile = language(config.language);
    var userMenuObject = {
        bridgeBarState: bridgeBarStates.ERROR,
        messages: [],
        userData: [],
        userLinks: [],
        logOut: buildLogOut(config)
    };

    var platformId = null,
        solutionSwitcherObject = null,
        solutionLinkObject = [],
        featureFlagsObject = {},
        components = [];

    if (data) {
        userMenuObject.bridgeBarState = bridgeBarStates.RENDER;
        if (data.feature_flags) {
            /* eslint-disable guard-for-in */
            for (var ffKey in data.feature_flags) {
                featureFlagsObject[ffKey] = data.feature_flags[ffKey].value;
            }

            /* eslint-enable */
        }

        // Get user initials for avatar
        userMenuObject.userLabelHtml = getInitials(data.given_name, data.family_name);

        // Build User Data
        if (data.display_info) {
            userMenuObject.userData = userMenuObject.userData.concat(getDisplayInfoData(data.display_info));
        }

        userMenuObject.logOut = buildLogOut(config);

        // Build User Links
        if (data.user_links) {
            userMenuObject.userLinks = data.user_links.filter(function(link) {
                return link.menu === ('user' || null);
            }).map(function(link) {
                var linkObj = {
                    className: link.icon
                };

                return populateLinkObject(linkObj, link.label, link.uri, link.mode);
            });

            // Build Solution Links
            solutionLinkObject = data.user_links.filter(function(link) {
                return link.menu === 'solution';
            }).map(function(link) {
                var linkObj = {
                    // TODO: The id is typically the Solution ID. Do we need it for 'solution' user links?
                    // For now, using the label (e.g. "Attach More Solutions" -> "AttachMoreSolutions")
                    id: link.label.replace(/[^a-zA-Z0-9]/g, ''),
                    className: link.icon
                };

                return populateLinkObject(linkObj, link.label, link.uri, link.mode);
            });
        }

        // If solution_links is omitted, show the user an error
        if (data.solution_links) {

            // Base Prototype for the Solution Switcher
            solutionSwitcherObject = {
                // This is originally from the api, do we want to move it to there?
                labelHtml: languageFile.solutions['cox-automotive-solutions'],

                currentId: config.solutionGroupCode || '',
                solutionGroups: [],
                solutionLinks: []
            };

            // If solution_links is an empty array, do not display the solution groups
            if (data.solution_links.length) {
                solutionSwitcherObject.solutionGroups = data.solution_links.map(function(link) {
                    var linkObj = {
                        id: link.solutionId
                    };

                    return populateLinkObject(linkObj, link.label, link.uri, link.mode);
                });
            }

            // If there are 'solution' user links, add them
            if (solutionLinkObject.length) {
                solutionSwitcherObject.solutionLinks = solutionLinkObject;
            }

        } else {
            userMenuObject.messages.push({
                messageHtml: languageFile['error-messages']['unable-to-load-Solution-Switcher'],
                level: 'warn'
            });
        }

        if (data.components) {
            components = data.components;
        }

        if (data.id) {
            platformId = data.id;
        }
    } else {
        // No Data
        userMenuObject.messages.push({
            messageHtml: languageFile['error-messages']['unable-to-load-personalized-information'],
            level: 'warn'
        });
    }

    return {
        platformId: platformId,
        userMenuObject: userMenuObject,
        solutionSwitcherObject: solutionSwitcherObject,
        settings: {
            showBridgeBar: true,
            error: loggedError,
            keepAliveUrl: bridgeApiUrlBuilder.getBridgeBarKeepAliveUrl(config)
        },
        feature_flags: featureFlagsObject,
        components: components
    };
}

function populateLinkObject(linkObj, label, url, mode) {
    linkObj.label = label;
    linkObj.url = url;

    var target = convertLinkMode(mode);

    if (target) {
        linkObj.target = target;
    }

    return linkObj;
}

function convertLinkMode(mode) {
    switch (mode) {
        case 'NEW_WINDOW': return '_blank';
        case 'PARENT_WINDOW': return '_parent';
        case 'TOP_WINDOW': return '_top';
        case 'CURRENT_WINDOW': return '_self';
        default:
            return '';
    }
}

function applyOptions(convertedData, options) {
    options = applyDefaults(options, {
        dealerLabelHtml: '',
        userLinks: [],
        userData: []
    });

    var userMenu = convertedData.userMenuObject;

    userMenu.dealerLabelHtml = options.dealerLabelHtml;
    userMenu.userData = userMenu.userData ?
        userMenu.userData.concat(options.userData) :
        options.userData;
    userMenu.userLinks = userMenu.userLinks ?
        userMenu.userLinks.concat(options.userLinks) :
        options.userLinks;
    return convertedData;
}

function buildLogOut(config) {
    var languageFile = language(config.language);

    // TODO: Remove deprecation notice after some time has passed
    if (config.logOut) {
        // eslint-disable-next-line no-console
        console.warn('The argument `config.logOut` is non-null. This field has been DEPRECATED and is now IGNORED');
    }

    return {
        label: languageFile['sign-out']['sign-out'],
        url: bridgeApiUrlBuilder.getLogOutUrl(config)
    };
}

function getInitials(givenName, familyName) {
    var initials = '';

    if (givenName) {
        initials = initials + givenName[0];
    }
    if (familyName) {
        initials = initials + familyName[0];
    }
    return (initials ? initials : '?');
}

function getDisplayInfoData(display_info) {
    return display_info.map(function(info) {
        return {
            keyHtml: info.header,
            valueHtml: info.body
        };
    });
}
